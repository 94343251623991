import { SiteDto } from '@appTypes/models/site.dto';
import { SECONDS_MINUTE } from '@settings/constants';
import { convertLocalDateToApiString, createParams } from '@utils/utils';
import { coreApi } from './core';

type AnalysisArgs = {
  siteId: SiteDto['id'];
};

type GraphArgs = {
  dataLink: string;
  startDate: Date;
  endDate: Date;
  limit: number;
};

export type PreviewParams = {
  siteId: string;
};

export type PreviewPayload = {
  name: string;
  items: string[];
};

type PreviewArgs = PreviewParams & PreviewPayload;

type YAxis = {
  unit: string;
  min: number;
  max: number;
};

type DataPoint = {
  name: string;
  value: [string, number];
};

type Series = {
  isEmpty: boolean;
  label: string;
  uniqueName: string;
  yaxisIndex: number;
  seriesMin: number;
  seriesMax: number;
  dataPoints: DataPoint[];
};

export type GraphDto = {
  graphId: string;
  title: string;
  dataLink: string;
  yaxes: YAxis[];
  series: Series[];
  emptySeries: string[];
};

const analysisApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnalysis: builder.query<GraphDto[], AnalysisArgs>({
      query: ({ siteId }) => `sites/${siteId}/analysis`,
    }),
    getGraph: builder.query<GraphDto, GraphArgs>({
      query: ({ dataLink, startDate, endDate, limit }) =>
        `${dataLink}?${createParams({
          limit,
          start: convertLocalDateToApiString(startDate),
          end: convertLocalDateToApiString(endDate),
          static_response: 'false',
        })}`,
      keepUnusedDataFor: SECONDS_MINUTE,
    }),
    getCustomGraphPreview: builder.mutation<GraphDto, PreviewArgs>({
      query: ({ siteId, ...payload }) => ({
        url: `sites/${siteId}/preview`,
        method: 'POST',
        data: { ...payload },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetAnalysisQuery, useGetGraphQuery, useGetCustomGraphPreviewMutation } =
  analysisApi;
