import React, { useEffect, useState } from 'react';
import { parseBigNumber } from '@components/Charts/helpers';
import { Typography, styled } from '@mui/material';
import { palette } from 'styles/palette';
import { degreesToRadians, isOnRightSide } from '../helpers';
import { DeviceParameterWithName, ENERGY_FLOW_NODE_COLORS } from '../types';
import Wire, { WireProps } from './Wire';

const Wrapper = styled('div')<{ animate: boolean }>`
  opacity: ${({ animate }) => (animate ? 0 : 1)};
  transform: ${({ animate }) => (animate ? 'scale(0.2)' : 'scale(1)')};

  transition: 1s all cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-property: opacity transform;
`;

const CardsWrapper = styled('div')`
  display: flex;
  gap: 5px;
`;

const Relative = styled('div')`
  position: relative;
`;

const Absolute = styled('div')`
  position: absolute;
  gap: 10px;
  display: flex;
  width: max-content;
  justify-content: flex-start;
  align-items: center;
`;

const Card = styled('div')`
  position: relative;
  border-radius: 5px;
  border: 1px solid ${palette.neutral.dark};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
`;

const CardValue = styled(Typography)`
  margin-top: auto;
  margin-bottom: auto;
  color: ${palette.neutral.dark};
  font-weight: bold;
`;

const CardUnit = styled(Typography)`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: flex-end;
  padding: 2px 0;
  font-weight: bold;
  background: ${palette.neutral.dark};
  color: ${palette.white};
  width: 100%;
  text-align: center;
`;

const Content = styled('div')<{ translateX: number; translateY: number }>`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  line-height: 18px;

  -webkit-transform: translate(
    ${({ translateX, translateY }) => `${translateX}px, ${translateY}px`}
  );

  transform: translate(${({ translateX, translateY }) => `${translateX}px, ${translateY}px`});
`;

type DeviceNodeProps = WireProps &
  React.HTMLAttributes<HTMLDivElement> & {
    color: ENERGY_FLOW_NODE_COLORS;
    parameters: DeviceParameterWithName[];
    icon: JSX.Element;
    iconOn?: JSX.Element;
    title?: string;
    cardSize?: number;
    showTitle?: boolean;
  };

const DeviceNode = ({
  cardSize = 46,
  title,
  className,
  parameters,
  icon,
  iconOn,
  style,
  color = ENERGY_FLOW_NODE_COLORS.default,
  rotation = 0,
  showTitle = true,
  ...wireProps
}: DeviceNodeProps) => {
  const { wireLength = 100 } = wireProps;
  const rtl = isOnRightSide(rotation);

  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    setAnimate(false);
  }, []);

  const wireThickness = cardSize * 0.7;
  const offsetX = cardSize / 2;
  const offsetY = (cardSize - wireThickness) / 2;

  const translateX = wireLength * Math.cos(degreesToRadians(rotation));
  const translateY = wireLength * Math.sin(degreesToRadians(rotation));

  return (
    <Wrapper className={className} style={style} animate={animate}>
      <Relative>
        <Wire
          style={{
            position: 'absolute',
            left: offsetX,
            top: offsetY,
          }}
          rotation={rotation}
          wireThickness={wireThickness}
          {...wireProps}
        />
        <Content
          style={{
            width: cardSize,
            height: cardSize,
          }}
          translateX={translateX}
          translateY={translateY}
        >
          <Absolute
            style={{
              left: rtl ? 0 : 'initial',
              right: rtl ? 'initial' : 0,
              flexDirection: rtl ? 'row-reverse' : 'row',
            }}
          >
            {title && showTitle && (
              <Typography data-testid="node-title" fontSize={14}>
                {title}
              </Typography>
            )}
            {!!iconOn && (parameters[0].value || 0) > 0 ? iconOn : icon}
            <CardsWrapper>
              {parameters?.map(({ value, unit, name }) => {
                const absoluteValue = Math.abs(value || 0);
                const [parsedValue, parsedUnit] = parseBigNumber(absoluteValue, unit, 1);

                return (
                  <Card
                    key={name}
                    style={{
                      width: cardSize,
                      height: cardSize,
                      backgroundColor: color,
                      containerType: 'inline-size',
                    }}
                  >
                    <CardValue
                      style={{
                        fontSize: '30cqw',
                      }}
                    >
                      {value === null ? '-' : parsedValue}
                    </CardValue>
                    <CardUnit
                      data-testid="card-unit"
                      style={{
                        fontSize: '20cqw',
                      }}
                    >
                      {parsedUnit}
                    </CardUnit>
                  </Card>
                );
              })}
            </CardsWrapper>
          </Absolute>
        </Content>
      </Relative>
    </Wrapper>
  );
};

export default DeviceNode;
