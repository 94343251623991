import { useEffect, useMemo, useState } from 'react';
import { UserDto, UserRole, UpdateUserDto, CreateUserDto } from '@appTypes/models/user.dto';
import { ButtonBack } from '@components/Buttons/Buttons';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { RelationsForm } from '@components/RelationsForm/RelationsForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCurrentRole, useDataByPreviousPath } from '@hooks';
import { fetchedGetUser } from '@redux/ducks/users/actionCreators';
import { getUsersSelector } from '@redux/ducks/users/selectors';
import { t } from '@utils/translate';
import _ from 'lodash';
import { paths } from 'paths';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFormFields } from './fields';
import { SitesRelations } from './Relations/SitesRelations';
import { validationSchema } from './validations';

type UserFormProps = {
  onSubmit(data: UpdateUserDto): void;
  userId?: UserDto['id'];
  viewOnlyMode?: boolean;
};

const defaultFormValueForCreate: CreateUserDto = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  role_name: UserRole.USER,
  organization_id: null,
};

const relations = {
  [ConfiguredItem.SITES]: {
    component: SitesRelations,
    addTxt: t('buttonAddRelatedSites'),
  },
};

export const UserForm = ({ onSubmit, userId, viewOnlyMode = false }: UserFormProps) => {
  const isCreateMode = userId === undefined;
  const [formReady, setFormReady] = useState(false);

  const dispatch = useDispatch();

  const { current } = useSelector(getUsersSelector);

  const { hasRole } = useCurrentRole({ inheritRolePermissions: false });

  const { loading, error, data: currentData } = current;

  useEffect(() => {
    const fetchUser = () => {
      if (isCreateMode) return;

      dispatch(fetchedGetUser(userId));
    };
    fetchUser();
  }, [dispatch, isCreateMode, userId]);

  const userForm = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, reset } = userForm;

  useEffect(() => {
    if (isCreateMode) {
      reset(defaultFormValueForCreate);
      setFormReady(true);
      return;
    }

    if (loading) return;

    if (error) {
      setFormReady(true);
      return;
    }

    if (currentData) {
      reset(
        _.pick({ ...currentData, role_name: currentData.role.name }, [
          'first_name',
          'last_name',
          'email',
          'phone',
          'role_name',
          'organization_id',
          'id',
          'creation_date',
        ]),
      );
      setFormReady(true);
    }
  }, [currentData, error, isCreateMode, loading, reset]);

  const fields = useMemo(
    () => getUserFormFields(isCreateMode, hasRole(UserRole.USER)),
    // TODO: Fix correct deps causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCreateMode],
  );

  const handleFormSubmit: SubmitHandler<FieldValues> = (formData) => {
    const data = {
      ...formData,
      organization_id:
        formData.organization_id === null ? null : parseInt(formData.organization_id, 10),
    } as unknown as UpdateUserDto;

    onSubmit(data);
  };

  const backBtnTxt = useDataByPreviousPath<string>(({ isPreviousPath, noPreviousPath }) => {
    if (isPreviousPath(paths.configuration) || noPreviousPath) {
      return t('buttonBackToConfigurationList');
    }

    if (isPreviousPath(paths.configurationOrganization)) {
      return t('buttonBackToOrganization');
    }

    return t('buttonBack');
  });

  return (
    <RelationsForm
      readOnly={viewOnlyMode}
      isCreateMode={isCreateMode}
      formReady={formReady}
      loading={loading}
      error={error}
      onSubmit={handleSubmit(handleFormSubmit)}
      relationsGridWrapperProps={{ relations }}
      basicFormProps={{
        ...userForm,
        fields,
      }}
      title={t('headingUserProfile')}
    >
      <ButtonBack path={paths.configuration} query={{ selectedItem: ConfiguredItem.USERS }}>
        {backBtnTxt}
      </ButtonBack>
    </RelationsForm>
  );
};
