import { useMemo } from 'react';
import { SiteSimpleDto } from '@appTypes/models/site.dto';
import { XDataGrid } from '@components/XDataGrid/XDataGrid';
import { useRedirect, useXDataGridConnect } from '@hooks';
import { Box, Button } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { fetchedSites, updateSitesFilter } from '@redux/ducks/sites/actionCreators';
import { getSitesSelector, SitesSelectorReturn } from '@redux/ducks/sites/selectors';
import { t } from '@utils/translate';

type SiteGridCellProps = GridCellParams<string, SiteSimpleDto>;

export type SitesListProps = {
  detailButton?: {
    text: string;
    onClick: (siteId: SiteSimpleDto['id'], redirect: ReturnType<typeof useRedirect>) => void;
  };
};

export const SitesList = ({
  detailButton: { text, onClick } = {
    text: t('buttonDetail'),
    onClick: (siteId, redirect) => redirect((paths) => paths.configurationSite(siteId)),
  },
}: SitesListProps) => {
  const redirect = useRedirect();

  const dataGridColumns = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: t('headerName'),
          flex: 0.5,
        },
        {
          field: 'organization.name',
          headerName: t('headerOrganization'),
          flex: 0.5,
          renderCell: ({ row }: SiteGridCellProps) => row.organization?.name,
        },
        {
          field: 'city',
          headerName: t('headerCity'),
          flex: 0.5,
          renderCell: ({ row }: SiteGridCellProps) => row.address.city,
        },
        {
          field: 'country',
          headerName: t('headerCountry'),
          minWidth: 250,
          renderCell: ({ row }: SiteGridCellProps) => (
            <Box width="100%" display="grid" alignItems="center" gridTemplateColumns="auto 110px">
              {row.address.country || <div />}
              <Button variant="outlined" onClick={() => onClick(row.id, redirect)}>
                {text}
              </Button>
            </Box>
          ),
        },
      ].map((column) => ({
        minWidth: 100,
        flex: 1,
        ...column,
      })),
    [onClick, redirect, text],
  );

  const sitesGridConnection = useXDataGridConnect<SitesSelectorReturn, SiteSimpleDto>({
    selector: getSitesSelector,
    currentItemsField: 'currentPageSites',
    fetchPageAction: fetchedSites,
    updateFilterAction: updateSitesFilter,
  });

  return <XDataGrid<SiteSimpleDto> {...sitesGridConnection} columns={dataGridColumns} />;
};
