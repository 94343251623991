import { useRef, useState } from 'react';
import { GraphDto, useGetCustomGraphPreviewMutation } from '@apiRtk/analysis';
import { FormValuesNewCustomGraph } from '@appTypes/forms';
import { ButtonPrimary } from '@components/Buttons/Buttons';
import ECharts from '@components/Charts/Echarts';
import { CenterX, CenterXY, FlexColumn } from '@components/LayoutUtils';
import { GraphLoader } from '@components/Spinner';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Alert, Box, Paper, Typography } from '@mui/material';
import { notificationService } from '@services/notificationService/notificationService';
import { t } from '@utils/translate';
import ReactECharts, { EChartsReactProps } from 'echarts-for-react';
import { UseFormTrigger } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type EChartsWrapperProps = Omit<EChartsReactProps, 'option'> & {
  name: string;
  metrics: string[];
  trigger: UseFormTrigger<FormValuesNewCustomGraph>;
};

const previewGraphHeight = 300;

const GraphPreview = ({ name, metrics, trigger, ...props }: EChartsWrapperProps) => {
  const params = useParams();
  const siteId = params.id || '';

  const [createPreview, { isLoading, isError }] = useGetCustomGraphPreviewMutation();
  const refs = useRef<Array<ReactECharts | null>>([]);

  const [previewData, setPreviewData] = useState<GraphDto | undefined>(undefined);
  const [hasRequestedPreview, setHasRequestedPreview] = useState(false);

  const handlePreviewButtonClick = async () => {
    const isFormValid = await trigger();
    if (isFormValid) {
      try {
        setHasRequestedPreview(true);

        const result = await createPreview({ siteId, name, items: metrics });

        if ('data' in result) {
          setPreviewData(result.data);
        } else if ('error' in result) {
          notificationService.error(t('errorCreatingPreview', { detail: result.error }));
        }
      } catch (error) {
        notificationService.error(t('errorCreatingPreview'));
      }
    }
  };

  let content;

  if (hasRequestedPreview && isLoading) {
    content = <GraphLoader />;
  } else if (isError) {
    content = <Alert severity="error">{t('errorGraphCommon')}</Alert>;
  } else if (previewData) {
    content = (
      <>
        <Typography variant="body1">{name}</Typography>
        <ECharts
          height={previewGraphHeight}
          refs={refs}
          deviceId="preview-graph"
          loading={isLoading}
          graphData={previewData}
          {...props}
        />
      </>
    );
  } else {
    content = (
      <CenterXY height={previewGraphHeight}>
        <Typography variant="body2">{t('infoSelectMetrics')}</Typography>
      </CenterXY>
    );
  }

  return (
    <FlexColumn>
      <CenterX pb={2}>
        <Typography variant="h6">{t('graphPreview')}</Typography>
        <ButtonPrimary
          startIcon={<VisibilityIcon />}
          size="small"
          sx={{ marginLeft: 'auto' }}
          onClick={handlePreviewButtonClick}
        >
          {t('buttonPreview')}
        </ButtonPrimary>
      </CenterX>
      <Paper>
        <Box
          sx={{
            minHeight: previewGraphHeight,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            padding: 2,
          }}
        >
          {content}
        </Box>
      </Paper>
    </FlexColumn>
  );
};

export default GraphPreview;
