import { CreateUserDto } from '@appTypes/models/user.dto';
import { useRequestSuccessAlert } from '@hooks';
import { fetchedCreateUser } from '@redux/ducks/users/actionCreators';
import { getUsersSelector } from '@redux/ducks/users/selectors';
import { t } from '@utils/translate';
import { useSelector, useDispatch } from 'react-redux';
import { UserForm } from './UserForm/UserForm';

const CreateUser = () => {
  const dispatch = useDispatch();

  const onSubmit = (data: CreateUserDto) => {
    dispatch(fetchedCreateUser(data));
  };

  const { create } = useSelector(getUsersSelector);

  useRequestSuccessAlert(create, t('successUserAdded'));

  return <UserForm onSubmit={onSubmit} />;
};

export default CreateUser;
