import { useEffect, useState } from 'react';
import { useGetOrganizationsAllForSelectQuery } from '@apiRtk/organizations';
import { useUpdateUserMutation } from '@apiRtk/users';
import { ApiErrorPayload } from '@appTypes/api/error';
import { UserDto, UserRole } from '@appTypes/models/user.dto';
import { ButtonCancel, ButtonEdit, ButtonSave, ButtonText } from '@components/Buttons/Buttons';
import FormViewMode from '@components/FormViewMode/FormViewMode';
import { Flex, GapY } from '@components/LayoutUtils';
import { Tooltip } from '@components/Tooltip/Tooltip';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCurrentUserInfo } from '@hooks';
import { CircularProgress, MenuItem, Select, TextField, Typography, Box } from '@mui/material';
import { notificationService } from '@services/notificationService/notificationService';
import { t } from '@utils/translate';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ToggleUserActivationModal } from './UserForm/Relations/ToggleUserActivationModal';
import { userFormSchema } from './UserFormSchema';

const defaultValues = {
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  role_name: UserRole.USER,
  organization_id: 0,
};

type FormValues = z.infer<typeof userFormSchema>;

interface UpdateUserFormProps {
  userId: number;
  userData?: UserDto;
}

const UpdateUserForm = ({ userId, userData }: UpdateUserFormProps) => {
  const { data: organizations, isLoading: isOrganizationsLoading } =
    useGetOrganizationsAllForSelectQuery();

  const { isSuperAdmin, isUserOnly } = useCurrentUserInfo();

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(userFormSchema),
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [toggleUserActivationModalOpen, setToggleUserActivationModalOpen] = useState(false);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await updateUser({
        id: userId,
        ...data,
        role: data.role_name,
        organization_id: data.organization_id === 0 ? null : data.organization_id,
        is_active: true,
      }).unwrap();
      notificationService.success(t('successUserUpdated'));
      setIsEditMode(false);
    } catch (err) {
      const typedError = err as ApiErrorPayload;
      notificationService.error(
        typedError && typeof typedError?.data?.detail === 'string'
          ? typedError.data.detail
          : t('errorCommon'),
      );
    }
  };

  const handleToggleUserActivation = async () => {
    try {
      await updateUser({
        id: userId,
        first_name: userData?.first_name || '',
        last_name: userData?.last_name || '',
        phone: userData?.phone || '',
        email: userData?.email || '',
        role: userData?.role.name || UserRole.USER,
        role_name: userData?.role.name || UserRole.USER,
        organization_id: userData?.organization?.id || null,
        is_active: !userData?.is_active,
      }).unwrap();
      notificationService.success(
        userData?.is_active ? t('successUserDeactivated') : t('successUserActivated'),
      );
    } catch (err) {
      const typedError = err as ApiErrorPayload;
      notificationService.error(
        typedError && typeof typedError?.data?.detail === 'string'
          ? typedError.data.detail
          : t('errorCommon'),
      );
    }
  };

  useEffect(() => {
    if (!userData) {
      return;
    }
    reset({
      email: userData.email,
      first_name: userData.first_name || '',
      last_name: userData.last_name || '',
      phone: userData.phone || '',
      role_name: userData.role?.name as UserRole,
      organization_id: userData.organization?.id,
    });
  }, [userData, reset]);

  if (isOrganizationsLoading) {
    return <CircularProgress />;
  }

  const fields = userData
    ? [
        { label: t('labelEmail'), value: userData.email },
        { label: t('labelFirstName'), value: userData.first_name },
        { label: t('labelLastName'), value: userData.last_name },
        { label: t('labelPhone'), value: userData.phone },
        { label: t('labelUserRole'), value: userData.role?.name },
        { label: t('labelOrganizationName'), value: userData.organization?.name },
      ]
    : [];

  const userRoles = [
    {
      id: UserRole.SUPERADMIN,
      name: UserRole.SUPERADMIN,
    },
    {
      id: UserRole.ADMIN,
      name: UserRole.ADMIN,
    },
    {
      id: UserRole.USER,
      name: UserRole.USER,
    },
  ];

  return (
    <Box>
      {isEditMode ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label={t('labelEmail')}
                value={value}
                error={!!errors.email}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled
              />
            )}
          />
          {errors.email && (
            <Typography variant="subtitle2" color="error">
              {errors.email.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="first_name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="First Name"
                value={value}
                error={!!errors.first_name}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.first_name && (
            <Typography variant="subtitle2" color="error">
              {errors.first_name.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="last_name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Last Name"
                value={value}
                error={!!errors.last_name}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.last_name && (
            <Typography variant="subtitle2" color="error">
              {errors.last_name.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="phone"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label={t('labelPhone')}
                value={value}
                error={!!errors.phone}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.phone && (
            <Typography variant="subtitle2" color="error">
              {errors.phone.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="role_name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                label={t('labelUserRole')}
                sx={{ width: '100%' }}
                value={value}
                error={!!errors.role_name}
                onChange={(event) => onChange(event.target.value as UserRole)}
                disabled={!isEditMode || isUserOnly}
              >
                {userRoles.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    disabled={item.id === UserRole.SUPERADMIN && !isSuperAdmin}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.role_name && (
            <Typography variant="subtitle2" color="error">
              {errors.role_name.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="organization_id"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                label={t('labelOrganization')}
                sx={{ width: '100%' }}
                value={value}
                error={!!errors.organization_id}
                onChange={onChange}
                disabled={!isEditMode}
              >
                <MenuItem key={0} value={0}>
                  -
                </MenuItem>
                {(organizations || []).map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.organization_id && (
            <Typography variant="subtitle2" color="error">
              {errors.organization_id.message}
            </Typography>
          )}

          <GapY size={2} />

          <Flex gap={1}>
            <ButtonSave size="large" type="submit" disabled={isLoading} />
            <ButtonCancel onClick={() => setIsEditMode(false)} disabled={isLoading} />
          </Flex>
        </form>
      ) : (
        <Box>
          {userData && <FormViewMode fields={fields} />}
          <Flex justifyContent="right" gap={1}>
            {isSuperAdmin && (
              <ButtonText
                onClick={() => setToggleUserActivationModalOpen(true)}
                color={userData?.is_active ? 'error' : 'info'}
              >
                {userData?.is_active ? t('buttonDeactivateUser') : t('buttonActivateUser')}
              </ButtonText>
            )}
            <Tooltip
              trigger={
                <ButtonEdit onClick={() => setIsEditMode(true)} disabled={!userData?.is_active} />
              }
              placement="top"
            >
              {!userData?.is_active ? t('tooltipCannotEditDeactivatedUser') : ''}
            </Tooltip>
          </Flex>
        </Box>
      )}
      <ToggleUserActivationModal
        user={userData}
        open={toggleUserActivationModalOpen}
        setOpen={setToggleUserActivationModalOpen}
        onConfirm={handleToggleUserActivation}
      />
    </Box>
  );
};

export default UpdateUserForm;
