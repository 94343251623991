import { BasicFormField } from '@components/BasicForm';
import { OrganizationsSelect } from '@components/OrganizationsSelect/OrganizationsSelect';
import { RolesSelect } from '@components/RolesSelect';
import { t } from '@utils/translate';

export const getUserFormFields = (isCreateMode: boolean, isUserRole: boolean): BasicFormField[] => {
  const isEditedByUser = !isCreateMode && isUserRole;
  return [
    {
      name: 'first_name',
      label: t('labelFirstName'),
    },
    {
      name: 'last_name',
      label: t('labelLastName'),
    },
    {
      name: 'email',
      label: t('labelEmail'),
      readOnly: !isCreateMode,
    },
    {
      name: 'phone',
      label: t('labelPhone'),
      inputProps: { maxLength: 15 },
    },
    {
      name: 'role_name',
      label: t('labelRole'),
      readOnly: isEditedByUser,
      CustomComponent: RolesSelect,
    },
    {
      name: 'organization_id',
      label: t('labelOrganization'),
      required: false,
      readOnly: isEditedByUser,
      CustomComponent: ({ ...props }) => (
        // @ts-ignore
        <OrganizationsSelect
          label={undefined}
          {...props}
          disableEmptyValue={false}
          emptyValueContent="-"
          formControlProps={{ sx: { width: '100%' } }}
        />
      ),
    },
    ...(isCreateMode
      ? []
      : [
          {
            name: 'id',
            label: t('labelId'),
            readOnly: true,
          },
          {
            name: 'creation_date',
            label: t('labelCreationDate'),
            readOnly: true,
            isDate: true,
          },
        ]),
  ];
};
