import { useState } from 'react';
import { useGetExportDevicesConfigQuery } from '@apiRtk/sites';
import { FormValuesNewCustomGraph } from '@appTypes/forms';
import { ButtonPrimary } from '@components/Buttons/Buttons';
import { EllipsisTypography } from '@components/EllipsisTypography';
import { Flex, FlexColumn } from '@components/LayoutUtils';
import FormModal from '@components/Modals/FormModal';
import { Spinner } from '@components/Spinner';
import { zodResolver } from '@hookform/resolvers/zod';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Box, TextField, Typography } from '@mui/material';
import { DevicesTree } from '@pages/Sites/Overview/DataExport/DevicesTree/DeviceTree';
import { notificationService } from '@services/notificationService/notificationService';
import { t } from '@utils/translate';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { palette } from 'styles/palette';
import { z } from 'zod';
import GraphPreview from './GraphPreview';

export const customGraphSchema = z.object({
  name: z
    .string({
      required_error: t('validationNameRequired'),
    })
    .min(1, t('validationNameRequired'))
    .max(30, t('validationCustomGraphNameMaxLength')),
  metrics: z.array(z.string()).nonempty({ message: t('validationMetricsMin') }),
});

const defaultValues: FormValuesNewCustomGraph = {
  name: 'New custom graph',
  metrics: [],
};

export const GraphEditor = () => {
  const params = useParams();
  const siteId = parseInt(params.id!, 10);
  const [open, setOpen] = useState(false);

  const { isLoading: exportDevicesConfigLoading, data: devicesConfigAdvanced } =
    useGetExportDevicesConfigQuery({ siteId }, { skip: !siteId || !open });

  const {
    handleSubmit,
    trigger,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<FormValuesNewCustomGraph>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(customGraphSchema),
  });

  const name = watch('name');
  const metrics = watch('metrics');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFormSubmit: SubmitHandler<FormValuesNewCustomGraph> = async (fieldValues) => {
    const isFormValid = await trigger();
    if (isFormValid) {
      try {
        // TODO: call mutation for storing new graph

        setOpen(false);
        reset();
      } catch (err) {
        notificationService.error(t('errorCreatingCustomGraph'));
      }
    }
  };

  return (
    <Flex>
      <ButtonPrimary
        startIcon={<AutoFixHighIcon />}
        size="small"
        sx={{ marginLeft: 'auto' }}
        onClick={() => setOpen(true)}
      >
        {t('buttonCreateCustomGraph')}
      </ButtonPrimary>
      <FormModal
        open={open}
        setOpen={setOpen}
        dialogTitle={t('dialogTitleGraphEditor')}
        submitDisabled={exportDevicesConfigLoading}
        onSubmit={handleSubmit(handleFormSubmit)}
        onCancel={() => reset()}
        confirmButtonCaption={t('buttonConfirm')}
        forceFullscreen
      >
        <Flex flexGrow={1} gap={2}>
          {exportDevicesConfigLoading ? (
            <Box p={2}>
              <Spinner />
            </Box>
          ) : (
            <FlexColumn flex="1" p={1} gap={2}>
              <Typography variant="h6">{t('headingGraphConfiguration')}</Typography>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <EllipsisTypography
                      maxWidth="100%"
                      tooltipProps={{ placement: 'top-start' }}
                      color={!errors.name ? palette.primary.light : palette.error.main}
                    >
                      {`${t('labelName')}*`}
                    </EllipsisTypography>
                    <TextField value={value} error={!!errors.name} onChange={onChange} />
                    {errors.name && (
                      <Typography variant="subtitle2" color="error">
                        {errors.name.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <EllipsisTypography
                maxWidth="100%"
                tooltipProps={{ placement: 'top-start' }}
                color={!errors.metrics ? palette.primary.light : palette.error.main}
              >
                {`${t('labelAvailableMetrics')}*`}
              </EllipsisTypography>
              {errors.metrics && (
                <Typography variant="subtitle2" color="error">
                  {errors.metrics.message}
                </Typography>
              )}

              <DevicesTree
                setValue={setValue}
                watch={watch}
                devices={devicesConfigAdvanced || []}
              />
            </FlexColumn>
          )}
          <FlexColumn flex="2" p={1}>
            <GraphPreview metrics={metrics} name={name} trigger={trigger} />
          </FlexColumn>
        </Flex>
      </FormModal>
    </Flex>
  );
};
