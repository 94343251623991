import { useRef } from 'react';
import { useGetAnalysisQuery } from '@apiRtk/analysis';
import { HttpStatusCode } from '@appTypes/api/error';
import { FEATURE_FLAGS } from '@appTypes/common';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import DateRangePanel from '@components/Charts/DateRangePanel/DateRangePanel';
import { DateTimeRangeProvider } from '@components/Charts/DateRangePanel/DateTimeRangeContext';
import EchartWrapper from '@components/Charts/EchartWrapper';
import { SiteHeaderWrapper } from '@components/SiteHeaderWrapper/SiteHeaderWrapper';
import { Spinner } from '@components/Spinner';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { selectAuthSlice } from '@redux/slices/auth';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import { prepareError } from '@utils/data/prepareError';
import { t } from '@utils/translate';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { GraphEditor } from './GraphEditor/GraphEditor';

const DataAnalysis = () => {
  const { currentUser } = useSelector(selectAuthSlice);

  const params = useParams();
  const siteId = parseInt(params.id!, 10);

  const refs = useRef<Array<Nullable<ReactECharts>>>([]);

  const { refetch, data: graphs, isLoading, error, isError } = useGetAnalysisQuery({ siteId });

  if (isError) {
    const errorMessage =
      prepareError(error)?.status === HttpStatusCode.NOT_FOUND
        ? t('infoSelectedSiteNotFound')
        : t('errorCommon');

    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <AlertWithRefetch onRetryClick={refetch}>{errorMessage}</AlertWithRefetch>
      </SiteHeaderWrapper>
    );
  }

  if (!graphs || isLoading) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <Spinner />
      </SiteHeaderWrapper>
    );
  }

  const currentSite = currentUser?.sites.find(({ id }) => id === siteId);
  const pageTitle = currentSite ? currentSite.name : '-';

  return (
    <SiteHeaderWrapper siteId={siteId} siteName={pageTitle}>
      <DateTimeRangeProvider>
        {isFeatureFlagEnabled(FEATURE_FLAGS.GRAPH_EDITOR) ? <GraphEditor /> : null}
        <DateRangePanel />
        {graphs.map(({ graphId, title, dataLink }, index) => (
          <Accordion
            key={graphId}
            style={{ marginTop: 10, backgroundColor: 'white' }}
            defaultExpanded
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="body1">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box marginTop="auto" marginBottom="auto">
                <EchartWrapper
                  refs={refs}
                  groupRef={(el) => {
                    refs.current[index] = el;
                  }}
                  height={250}
                  deviceId={graphId}
                  dataLink={dataLink}
                  index={index}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </DateTimeRangeProvider>
    </SiteHeaderWrapper>
  );
};

export default DataAnalysis;
