import { GraphDto } from '@apiRtk/analysis';
import { formatNumberWithUnit, getChartColors } from '@components/Charts/helpers';
import { EChartsOption, LineSeriesOption, YAXisComponentOption } from 'echarts';
import ReactDOMServer from 'react-dom/server';
import { palette } from 'styles/palette';
import EchartsTooltip from './EchartsTooltip';

const yAxisWidth = 60;

const defaultGrid = {
  top: 10,
  bottom: 30,
  left: yAxisWidth,
  right: yAxisWidth,
};

export const getStaticOptions = (): EChartsOption => ({
  animation: false,

  xAxis: {
    type: 'time',
    minorTick: {
      show: true,
    },
    splitLine: {
      show: true,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: 'black',
      },
    },

    axisLabel: {
      align: 'center',
    },

    axisPointer: {
      type: 'line',
      show: true,
      label: {
        show: false,
      },
      lineStyle: {
        color: palette.error.dark,
        width: 1,
        type: 'solid',
      },
    },
  },

  tooltip: {
    transitionDuration: 0,
    trigger: 'axis',
    axisPointer: {
      label: {
        show: false,
      },
    },
    formatter(params) {
      const content = ReactDOMServer.renderToString(<EchartsTooltip params={params} />);

      return content;
    },
  },
  legend: {
    show: false,
  },

  toolbox: {
    itemSize: 0,
    feature: {
      dataZoom: {
        show: true,
        yAxisIndex: false,
        brushStyle: {
          opacity: 0.3,
          color: palette.success.light,
        },
      },
    },
  },
});

export type DynamicOptions = {
  series: LineSeriesOption[];
  yAxis: YAXisComponentOption[];
  grid: any;
};

export const createDynamicOptions = (
  graphData: Nullable<GraphDto> | undefined,
  hiddenSeries: string[],
): DynamicOptions => {
  if (!graphData?.series?.length || !graphData?.yaxes?.length) {
    return {
      series: [],
      yAxis: [],
      grid: defaultGrid,
    };
  }

  const { series: allSeries, yaxes } = graphData;

  const visibleSeries: LineSeriesOption[] = [];

  const yAxisConfigs: YAXisComponentOption[] = yaxes.map((yAxisConfig, index) => ({
    show: false, // Default to not show, will be set to true if any series uses it, serves also as flag, that yaxis contains visible series
    min: yAxisConfig.min,
    max: yAxisConfig.max,
    axisLabel: {
      formatter: (value: number) => formatNumberWithUnit(value, yAxisConfig.unit),
    },
    position: index === 0 ? 'left' : 'right',
    axisTick: {
      show: true,
    },
  }));

  const chartColors = getChartColors();

  allSeries.forEach((s, index) => {
    if (hiddenSeries.includes(s.uniqueName)) {
      return;
    }

    const yAxisIndex = s.yaxisIndex;
    const yAxisConfig = yAxisConfigs[yAxisIndex];
    yAxisConfig.show = true;

    visibleSeries.push({
      name: s.uniqueName,
      color: chartColors[index],
      type: 'line',
      showSymbol: false,
      animation: false,
      lineStyle: { width: 0.8 },
      yAxisIndex,
      data: s.dataPoints,
      sampling: 'average',
      id: `${s.label}|${yaxes[yAxisIndex].unit}`,
    });
  });

  const yAxisConfigsLeft = yAxisConfigs.filter((config) => config.position === 'left');
  const yAxisConfigsRight = yAxisConfigs.filter((config) => config.position === 'right');

  const yAxisLeftVisible = yAxisConfigsLeft.filter((config) => config.show);
  const yAxisRightVisible = yAxisConfigsRight.filter((config) => config.show);

  const yAxisLeft = yAxisConfigsLeft.map((yAxisConfig) => ({
    ...yAxisConfig,
    offset: 0,
    axisLine: {
      show: yAxisConfig.show,
      lineStyle: {
        color: 'black',
      },
    },
    splitLine: {
      lineStyle: {
        width: yAxisConfig.show ? 1 : 0,
      },
    },
  }));

  const yAxisRight = yAxisConfigsRight.reduce<YAXisComponentOption[]>((acc, yAxisConfig) => {
    const visibleAxisCount = acc.filter((axis) => axis.axisLine?.show).length;

    const newOffset = yAxisConfig.show ? visibleAxisCount * yAxisWidth : 0;

    acc.push({
      ...yAxisConfig,
      offset: newOffset,
      axisLine: {
        show: yAxisConfig.show,
        lineStyle: {
          color: 'black',
        },
      },
      splitLine: {
        lineStyle: {
          width: yAxisLeftVisible.length > 0 ? 0 : 1,
        },
      },
    });

    return acc;
  }, []);

  const gridRightOffset = yAxisRightVisible.length * yAxisWidth;

  return {
    series: visibleSeries,
    yAxis: [...yAxisLeft, ...yAxisRight],
    grid: {
      ...defaultGrid,
      right: gridRightOffset || defaultGrid.right,
    },
  };
};
