import { PropsWithChildren } from 'react';
import { ErrorLocal, HttpStatusCode } from '@appTypes/api/error';
import { BasicForm, BasicFormProps } from '@components/BasicForm';
import { FullscreenInfo } from '@components/FullscreenInfo';
import { Spinner } from '@components/Spinner';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { Box, Grid } from '@mui/material';
import { t } from '@utils/translate';
import { RelationsGridWrapper, RelationsGridWrapperProps } from './RelationsGridWrapper';

type RelationsFormProps = PropsWithChildren<{
  isCreateMode: boolean;
  loading: boolean;
  error: ErrorLocal;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  relationsGridWrapperProps: RelationsGridWrapperProps;
  title: string;
  basicFormProps: Omit<BasicFormProps, 'readOnly'>;
  formReady?: boolean;
  readOnly?: boolean;
}>;

export const RelationsForm = ({
  isCreateMode,
  loading,
  formReady = true,
  readOnly = false,
  error,
  onSubmit,
  relationsGridWrapperProps,
  title,
  basicFormProps,
  children,
}: RelationsFormProps) => {
  if ((!isCreateMode && loading) || !formReady) {
    return <Spinner />;
  }

  if (!isCreateMode && error && !loading) {
    const errorMessage =
      error?.status === HttpStatusCode.NOT_FOUND
        ? t('errorNotFound', { name: title })
        : t('errorCommon');

    return <FullscreenInfo>{errorMessage}</FullscreenInfo>;
  }

  return (
    <Box component="form" onSubmit={onSubmit} noValidate>
      {children}
      <TitleBar my={4} title={title} titleVariant="h5" />
      <Grid
        container
        columns={3}
        spacing={{ xs: 4, lg: 10 }}
        width="100%"
        maxWidth={isCreateMode ? 800 : undefined}
        px={2}
        pt={0}
      >
        <Grid item md={3} lg={isCreateMode ? 3 : 1} pt={0}>
          <BasicForm columns={1} {...basicFormProps} readOnly={readOnly} />
        </Grid>
        {!isCreateMode && (
          <Grid item flex={1}>
            <RelationsGridWrapper {...relationsGridWrapperProps} showAddRelatedButton={!readOnly} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
