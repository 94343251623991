export const dictionary = {
  //
  // 1) Additional Range Presets
  //
  rangeLast12h: 'last 12h',
  rangeLast15m: 'last 15m',
  rangeLast1d: 'last 1d',
  rangeLast1h: 'last 1h',
  rangeLast2d: 'last 2d',
  rangeLast30d: 'last 30d',
  rangeLast6h: 'last 6h',
  rangeLast7d: 'last 7d',
  rangeLast90d: 'last 90d',
  rangeThisMonth: 'this month',
  rangeThisWeek: 'this week',
  rangeToday: 'today',
  rangeYesterday: 'yesterday',

  //
  // 2) Alt text
  //
  altLogo: 'logo',

  //
  // 3) Button Labels
  //
  buttonActivateUser: 'Activate user',
  buttonAddGeneric: 'Add',
  buttonAddNewRule: 'Add new rule',
  buttonAddRelatedEcd: 'Add related ECD',
  buttonAddRelatedSites: 'Add related sites',
  buttonAddRelatedUser: 'Add related user',
  buttonApply: 'Apply',
  buttonBack: 'Back',
  buttonBackToConfigurationList: 'Back to configuration list',
  buttonBackToOrganization: 'Back to organization',
  buttonCancel: 'Cancel',
  buttonClose: 'Close',
  buttonConfirm: 'Confirm',
  buttonCreateCustomGraph: 'Create custom graph',
  buttonCreateNewGraph: 'Create new graph',
  buttonCreateOrganization: 'Create organization',
  buttonCreateSite: 'Create site',
  buttonCreateUser: 'Create user',
  buttonDeactivateUser: 'Deactivate user',
  buttonDetail: 'Detail',
  buttonDone: 'Done',
  buttonDownloadData: 'Download data',
  buttonEdit: 'Edit',
  buttonExportToCsv: 'Export to CSV',
  buttonGoToDataAnalysis: 'Go to data analysis',
  buttonGoToHomepage: 'Go to Homepage',
  buttonGoToNotificationsList: 'Go to notifications list',
  buttonLogin: 'Log in',
  buttonLogout: 'Logout',
  buttonPreview: 'Preview',
  buttonReject: 'Reject',
  buttonRestartEcd: 'Restart ECD',
  buttonRetry: 'Retry',
  buttonReturnToMainAccount: 'Return to main account',
  buttonSave: 'Save',
  buttonSendChanges: 'Send {{count}} change{{changesSuffix}}',
  buttonSendEmail: 'Send e-mail',
  buttonSetNewPassword: 'Set new password',
  buttonSetupRules: 'Setup rules',
  buttonSiteDetail: 'Site detail',
  buttonUpdate: 'Update',
  buttonUserDetail: 'User detail',
  buttonVerifyCode: 'Verify code',
  buttonZoomIn: 'Zoom In',
  buttonZoomOut: 'Zoom Out',
  graphPreview: 'Graph preview',

  //
  // 4) Date Range Presets
  //
  rangePreset10Mins: '10 Minutes',
  rangePreset12Hrs: '12 hrs',
  rangePreset15Mins: '15 Minutes',
  rangePreset1Hour: '1 Hour',
  rangePreset1Hr: '1 hr',
  rangePreset24Hours: '24 Hours',
  rangePreset24Hrs: '24 hrs',
  rangePreset30Days: '30 days',
  rangePreset30Mins: '30 Minutes',
  rangePreset6Hrs: '6 hrs',
  rangePreset7Days: '7 days',
  rangePreset90Days: '90 days',
  rangePresetCustom: 'Custom',
  rangePresetCustomRange: 'Custom range',
  rangePresetLastMonth: 'Last month',
  rangePresetLastWeek: 'Last week',
  rangePresetPrevMonth: 'Prev month',
  rangePresetPrevWeek: 'Prev week',
  rangePresetThisMonth: 'This month',
  rangePresetThisWeek: 'This week',
  rangePresetToday: 'Today',
  rangePresetYesterday: 'Yesterday',

  //
  // Section Titles
  //
  titleDataDownload: 'Data Download',
  titleDataFormat: 'Data format',
  titleDataType: 'Data type',
  titleTimePeriod: 'Time period',
  titleValueMultiplier: 'Value multiplier & data granularity',

  //
  // 5) Dialog Text
  //
  dialogTextConfirmDeleteRule: 'Do you really want to delete the following rule?',
  dialogTextRestartEcd1: 'Confirming this action will trigger a restart for the following device',
  dialogTextRestartEcd2: 'This operation typically requires a few minutes to complete',
  dialogTextRestartEcd3: 'Would you like to proceed?',
  dialogTextUserActivation:
    'Are you sure you want to add {{lastName}} {{firstName}} to Podium? They will regain access to the platform',
  dialogTextUserDeactivation:
    'Are you sure you want to remove {{lastName}} {{firstName}} from Podium? They will no longer have access to the platform',

  //
  // 6) Dialog Titles
  //
  dialogTitleAddRelatedEcd: 'Add related ECD',
  dialogTitleAddRelatedSite: 'Add related site',
  dialogTitleAddRelatedUser: 'Add related user',
  dialogTitleConfirmAction: 'Confirm action',
  dialogTitleDataExport: 'Data export',
  dialogTitleDataExportRequest: 'Data export request',
  dialogTitleDeleteRule: 'Delete rule',
  dialogTitleSetEcdPermission: 'Set ECD permission',
  dialogTitleGraphEditor: 'Custom graph editor',

  //
  // 7) ECD Permission Titles
  //
  ecdPermissionAdvancedEdit: 'Advanced edit',
  ecdPermissionBasicEdit: 'Basic edit',
  ecdPermissionView: 'View',

  //
  // 8) Error Messages
  //
  errorActionError: '{{action}}: An error occurred',
  errorAddEcdToSite: 'Some error occured while adding ECD to site',
  errorAddSiteToOrganization: 'Error adding site to organization',
  errorAddUserToOrganization: 'Error adding user to organization',
  errorAddUserToSite: 'Some error occured while adding user to site',
  errorAppBehindFirewall:
    'Podium application was not able to contact our servers, this can be caused by firewall, please try open Podium from another network',
  errorAuthenticationFailed: 'Authentication failed',
  errorBookmarkLimitReached:
    'Cannot bookmark site {{name}}. Maximum capacity of {{maximumCapacity}} sites reached',
  errorCommon: 'Some unexpected error occured, please try again later',
  errorCommonAction:
    'We encountered an error while processing your request. Please try again later',
  errorEndDateAfterStartDate: 'Ensure end date is after start date',
  errorGetEcds: 'Some error occurred while getting ECDs, please try again later',
  errorGetNotificationDetail: 'Error getting notification detail',
  errorGetOrganizations: 'Some error occurred while getting Organizations, please try again later',
  errorGetSites: 'Some error occurred while getting sites, please try again later',
  errorGetUsers: 'Some error occurred while getting Users, please try again later',
  errorGraphCommon: 'An issue occurred while retrieving graph data',
  errorGraphDrawing:
    'An error occurred while drawing the graph. If the problem persists, contact the application vendor',
  errorLiveDiagramDataNotAvailable: 'Live diagram data not available, please try again later',
  errorNoData: 'No data available. Please try again later',
  errorNoEcdFound: 'No ECD found for this site. Please contact support',
  errorNoUserSelected: 'No user selected',
  errorNotFound: '{{name}} not found',
  errorOffline: 'You are currently offline. Please check your internet connection',
  errorPerformanceOverTimeNoData:
    'Performance over time data is not available for now, try again later',
  errorSiteAlreadyBookmarked: 'Site {{name}} is already bookmarked',
  errorSiteNameNotBookmarked: 'Site {{name}} is not bookmarked',
  errorSiteNotBookmarked: 'Site is not bookmarked',
  errorSomethingUnexpectedHappenedButWeAreHereToHelp:
    'Something unexpected happened, but we are here to help',
  errorTimePeriodAtLeastOneHour: 'Time period must differ by at least one hour',
  errorTimePeriodTooShort: 'Time period must differ by at least one hour',
  errorToggleRule: 'An error occured while toggling rule',
  errorCreatingPreview: 'Error creating preview {{detail}}',
  errorCreatingCustomGraph: 'Error creating custom graph',

  //
  // 9) Headings
  //
  headingAddNewRule: 'Add new rule',
  headingAdminTools: 'Admin tools',
  headingApplication: 'Application',
  headingBatteryStorage: 'Battery storage',
  headingBenefitCalculation: 'Benefit Calculation',
  headingConsumptionMix: 'Consumption mix',
  headingCreateSite: 'Create new site',
  headingDataFormat: 'Data format',
  headingDataGranularity: 'Data granularity',
  headingDataType: 'Data type',
  headingDevices: 'Devices',
  headingEcdConfiguration: '{{subject}} - Configuration',
  headingEcdDetails: 'ECD details',
  headingFeatures: 'Features',
  headingGlobalAlarms: 'Global alarms',
  headingGlobalConfiguration: 'Global configuration',
  headingGlobalNotifications: 'Global notifications',
  headingGuardYourEnergy: 'Guard your energy',
  headingLoggedUser: 'Logged user',
  headingLostPassword: 'Lost password',
  headingNotificationRules: 'Notification rules',
  headingPerformanceOverTime: 'Performance over time',
  headingPleaseEnterCredentials: 'Please enter your credentials',
  headingRenewableEnergy: 'Renewable energy',
  headingSiteDetails: 'Site Details',
  headingSiteOverview: 'Site overview',
  headingSystemStatus: 'System status',
  headingTimePeriod: 'Time period',
  headingUserDetails: 'User details',
  headingUserProfile: 'User profile',
  headingYourSites: 'Your sites',
  headingEditRule: 'Edit rule',
  headingUnit: 'Unit',
  headingSelectDateRange: 'Please select date range',
  headingGraphConfiguration: 'Graph configuration',

  //
  // 10) Info Messages
  //
  infoCannotRetrieveEcdTryAgainLater: 'Cannot retrieve ECD detail. Please try again later',
  infoClickRetryOrHomepage:
    'Click the Retry button to give it another go, or use the Go to Homepage button to navigate back',
  infoCommon: 'We encountered an issue while processing your request. Please try again later',
  infoContactSupportTeam: 'contact our support team',
  infoCookiesMessage:
    'Our application collects and monitors user data for google analytics. Please accept the condition to select the best content for you',
  infoDataExportNoteUtc: 'Note: Data will be exported in UTC',
  infoDataExportRequestReceived:
    'Data export request received, we will prepare your data and send it to you via email shortly',
  infoDataExportSelectTimePeriod:
    'Select the time period, type, and granularity of the data you want to export',
  infoECDNotFound: 'ECD not found',
  infoForAssistance: 'for assistance',
  infoIfYouAreStillFacingIssues: 'If you are still facing issues',
  infoNewVersionReleased: 'New version of Podium has been released!',
  infoNoBookmarkedSites: 'There are no bookmarked sites for the current user',
  infoPleaseEnterVerificationCode: 'Please enter the verification code sent to {{email}}',
  infoPleaseWait: 'Please wait',
  infoPreparingGraphData: 'Preparing graph data',
  infoSelectedSiteNotFound: 'Selected site was not found',
  infoSimplyHitUpdate:
    'Simply hit the Update button to refresh your page and update your app to the newest version',
  infoSelectMetrics:
    'Select the metrics you want to include in your graph, then click the preview button',

  //
  // 11) Labels
  //
  labelActive: 'Active',
  labelAll: 'All',
  labelAllRawDataAvailable: 'All raw data available',
  labelAvailableMetrics: 'Available metrics',
  labelAvoided: 'avoided',
  labelBalanceWithWattstor: 'Balance with Wattstor',
  labelBalanceWithoutWattstor: 'Balance without Wattstor',
  labelBattery: 'Battery',
  labelBatteryCharging: 'Battery charging',
  labelBatteryDischarging: 'Battery discharging',
  labelBatteryStorage: 'Battery storage',
  labelBenefit: 'Benefit',
  labelCarbonEmissionsAvoided: 'And carbon emissions avoided',
  labelCity: 'City',
  labelClearSort: 'Clear sort',
  labelComingSoon: 'Coming soon:',
  labelComparisonBatteryCharge: 'Battery charging (kW)',
  labelComparisonBatteryDischarge: 'Battery discharging (kW)',
  labelComparisonConsumption: 'Consumption (kW)',
  labelComparisonGridBackflow: 'Grid backflow (kW)',
  labelComparisonGridConsumption: 'Grid consumption (kW)',
  labelComparisonPvGeneration: 'PV generation (kW)',
  labelComparisonSoc: 'SoC (%)',
  labelComparisonSoh: 'SoH (%)',
  labelConsumption: 'Consumption',
  labelCountry: 'Country',
  labelCreationDate: 'Creation date',
  labelCyclesDayAverage: 'Cycles/day average',
  labelDevice: 'Device',
  labelDeviceFlows: 'Flows',
  labelEcd: 'ECD',
  labelEcdDevicesPermissions: 'ECD devices permissions',
  labelEcdId: 'ID',
  labelEcdName: 'Name',
  labelEmail: 'E-mail',
  labelEnableForThisSite: 'Enable for this site',
  labelEndDate: 'End date',
  labelEnergyImportAndExportBalance: 'Energy import and export balance',
  labelEnvironment: 'Environment',
  labelExportInfo: 'Select the time period, type, and granularity of the data you want to export',
  labelExportedToTheGrid: 'Exported to the grid',
  labelFeatureFlagDisableComingSoonOverlays: 'Disable coming soon overlays',
  labelFeatureFlagNewEnergyFlowDesign: 'New energy flow design',
  labelFeatureFlagAdvancedDataExport: 'Advanced data export (only visual using mocked data)',
  labelFeatureFlagCustomGraphEditor: 'Custom graph editor',
  labelFirstName: 'First Name',
  labelFromGrid: 'from grid',
  labelFromRenewables: 'from renewables',
  labelGranularity10Minutes: '10 Minutes',
  labelGranularity15Minutes: '15 Minutes',
  labelGranularity30Minutes: '30 Minutes',
  labelGranularity1Hour: '1 Hour',
  labelGranularity24Hours: '24 Hours',
  labelGrid: 'Grid',
  labelGridBackflow: 'Grid backflow',
  labelGridConsumption: 'Grid consumption',
  labelId: 'Id',
  labelInactive: 'Inactive',
  labelIs: 'Is',
  labelIsActive: 'Is active',
  labelLastModifyDate: 'Last modify date',
  labelLastName: 'Last Name',
  labelLatitude: 'Latitude',
  labelLoading: 'Loading...',
  labelLongitude: 'Longitude',
  labelManagerEmail: 'Manager Email',
  labelManagerName: 'Manager Name',
  labelManagerPhone: 'Manager Phone',
  labelMakeupOfYourEnergy: 'The makeup of your energy',
  labelMoreDetailsComingSoon: 'More details coming soon',
  labelName: 'Name',
  labelNo: 'No',
  labelNoData: 'No data',
  labelNoDataOutage: 'No data (outage)',
  labelNoRows: 'No rows',
  labelNoUserSelected: 'No user selected',
  labelNotifyByEmail: 'Notify by e-mail',
  labelOf: 'of',
  labelOptimizationCurrency: 'Currency',
  labelOptimizationScope: 'Optimization Scope',
  labelOptimizationType: 'Optimization Type',
  labelOrganization: 'Organization',
  labelOrganizationName: 'Organization Name',
  labelPassword: 'Password',
  labelPhone: 'Phone',
  labelPlaceholderNoName: '-',
  labelPostalCode: 'Postal Code',
  labelRenewableGeneration: 'Renewable generation',
  labelRenewables: 'Renewables',
  labelRenewablesGenerated: 'Renewables generated',
  labelRole: 'Role',
  labelSearch: 'Search',
  labelSearchEcd: 'Search ECD',
  labelSearchSite: 'Search site',
  labelSearchUser: 'Search user',
  labelSelectDataFormat: 'Select data format',
  labelSelectFormat: 'Select data format',
  labelSelectGranularity: 'Select granularity',
  labelSelectMultiplier: 'Select value multiplier',
  labelSelectPeriodToShow: 'Select period to show',
  labelSelectUnit: 'Select unit',
  labelSeriesNoDataInfo: 'No data available in this timeframe',
  labelSite: 'Site',
  labelSiteLoad: 'Site load',
  labelSolarProduction: 'Solar Production',
  labelSortAscending: 'Sort ascending',
  labelSortDescending: 'Sort descending',
  labelStartDate: 'Start date',
  labelStateOfYourBattery: 'The state of your battery',
  labelStatus: 'Status',
  labelStreet: 'Street',
  labelSwitchAccount: 'Switch account',
  labelTimeFrom: 'From',
  labelTimeTo: 'To',
  labelTimezone: 'Timezone',
  labelTotalConsumption: 'Total consumption',
  labelTotalCycles: 'Total cycles',
  labelUnit: 'Unit',
  labelUserId: 'User ID',
  labelUserRole: 'User role',
  labelValue: 'Value',
  labelVerificationCode: 'Verification code',
  labelVersion: 'Version',
  labelWhTotalCharge: 'Wh Total charge',
  labelWhTotalDischarge: 'Wh Total discharge',
  labelWhenInSite: 'When in site',
  labelYes: 'Yes',
  labelYourSites: 'Your sites',
  labelEvCharging: 'EV charging',

  //
  // 12) Link Titles
  //
  linkBackToHomepage: 'Back to homepage',
  linkGoBackToLogin: 'Go back to login',
  linkLostPassword: 'Lost password?',

  //
  // 13) Options
  //
  optionEur: 'EUR - €',
  optionFullyDynamicMode: 'Fully Dynamic mode',
  optionGbp: 'GBP - £',
  optionHicMode: 'HIC mode',
  optionImportExportOptimisation: 'Import & Export optimisation',
  optionImportOptimisation: 'Import optimisation',
  optionNominationsMode: 'Nominations mode',
  optionOther: 'Other',

  //
  // 14) Placeholders
  //
  placeholderNoName: '-',
  placeholderSelectUser: 'Select user',

  //
  // 15) Role Labels
  //
  roleManager: 'Manager',
  roleTechnician: 'Technician',
  statusUserDeactivated: 'DEACTIVATED',

  //
  // 16) Success Messages
  //
  successActionSuccess: '{{action}}: Success',
  successCodeVerification: 'Code verification was successful',
  successEcdAdded: 'ECD was successfully added',
  successOrganizationCreated: 'Organization successfully created',
  successOrganizationUpdated: 'Organization updated successfully',
  successPasswordChanged: 'Password has been successfully changed',
  successRuleToggle: 'Rule was succesfully {{status}}',
  successSaved: 'Saved',
  successSiteAdded: 'Site successfully added',
  successSiteBookmarked: 'Site {{name}} successfully bookmarked',
  successSiteCreated: 'Site successfully created',
  successSiteOptimizationUpdate: 'Site optimization successfully updated',
  successSiteUnbookmarked: 'Site {{name}} successfully unbookmarked',
  successUserActivated: 'User activated successfully',
  successUserAdded: 'User successfully added',
  successUserDeactivated: 'User deactivated successfully',
  successUserPermissionsSet: 'User permissions set successfully',
  successUserUpdated: 'User updated successfully',
  successVerificationCodeSent: 'Verification code has been sent to your email address',

  //
  // 17) Table Headers
  //
  headerBookmark: 'Bookmark',
  headerCity: 'City',
  headerConnect: 'Connect',
  headerCountry: 'Country',
  headerDateTime: 'Date & time',
  headerDevice: 'Device',
  headerEcdId: 'ECD ID',
  headerEcdName: 'ECD Name',
  headerEcdPermissions: 'ECD Permissions',
  headerEmail: 'E-mail',
  headerLocation: 'Location',
  headerMainContact: 'Main Contact',
  headerMessage: 'Message',
  headerName: 'Name',
  headerNotification: 'Notification',
  headerOrganization: 'Organization',
  headerRole: 'Role',
  headerSite: 'Site',
  headerStatus: 'Status',
  headerUser: 'User',

  //
  // 18) Tabs
  //
  tabActive: 'Active',
  tabAlarms: 'Alarms',
  tabAll: 'All',
  tabConfiguration: 'Configuration',
  tabDataAnalysis: 'Data Analysis',
  tabEcds: 'ECDs',
  tabInactive: 'Inactive',
  tabNotifications: 'Notifications',
  tabOrganizations: 'Organizations',
  tabOverview: 'Overview',
  tabSites: 'Sites',
  tabUsers: 'Users',

  //
  // 19) Tooltips
  //
  tooltipAvoidedEmmisions:
    'We calculate the avoided emissions for the total renewable energy you generate, as well as the renewable energy you consume yourself',
  tooltipBatteryHealth:
    'To keep track of your battery health and performance, we calculate the cycles per day, total cycles, as well as the total charge and discharge over time',
  tooltipCannotAddSitesDeactivatedUser: 'Cannot add sites for a deactivated user',
  tooltipCannotEditDeactivatedUser: 'Cannot edit a deactivated user',
  tooltipCannotEditEcdPermissionsDeactivatedUser:
    'Cannot edit ECD permissions for a deactivated user',
  tooltipCannotEditEcdPermissionsNotEditable:
    "To change ECD permissions, first click 'Add related user' to re-add this user. You can then update their permissions",
  tooltipConsiderSolarProduction:
    'Consider Solar production when calculating the financial benefit',
  tooltipConsumptionMix:
    'An overview of the proportion of generated renewable energy and grid energy your site is consuming over time. We are aware that there is an issue with the calculation of total consumption and we are working on fixing it',
  tooltipDataExportInUtc: 'Data will be exported in UTC',
  tooltipReturnOnInvestment:
    'We calculate your financial benefit by comparing the performance of your site with and without the Wattstor system. We take into account energy and non commodity cost savings plus energy export revenue (if applicable)',
  tooltipSystemStatus: 'The status of your energy system in real time',

  //
  // 20) Validation Messages
  //
  validationCityInvalidCharacters: 'City must not contain special characters',
  validationCityInvalidType: 'City must be a string',
  validationCityMaxLength: 'City must contain at most 50 characters',
  validationCityMinLength: 'City must contain at least 2 characters',
  validationCityRequired: 'City is required',
  validationComparisonFieldRequired: 'Comparison field is required',
  validationConditionValueBatteryRange:
    'Condition value must be between 0 and 100 for battery devices',
  validationConditionValueCalculatorRange:
    'Condition value must be greater than or equal to 0 for calculator devices',
  validationConditionValueInvalidType: 'Condition value must be a number',
  validationConditionValueRequired: 'Condition value is required',
  validationCountryInvalidCharacters: 'Country must not contain special characters',
  validationCountryInvalidType: 'Country must be a string',
  validationCountryMaxLength: 'Country must contain at most 30 characters',
  validationCountryMinLength: 'Country must contain at least 4 characters',
  validationCountryRequired: 'Country is required',
  validationCustomGraphNameMaxLength: 'First Name must contain at most 50 characters',
  validationEmailInvalidType: 'E-mail must be valid',
  validationEmailListInvalid: 'Invalid e-mail format',
  validationEmailRequired: 'E-mail is required',
  validationFirstNameInvalidCharacters: 'First Name must not contain special characters',
  validationFirstNameInvalidType: 'First Name must be a string',
  validationFirstNameMaxLength: 'First Name must contain at most 50 characters',
  validationFirstNameMinLength: 'First Name must contain at least 2 characters',
  validationFirstNameRequired: 'First Name is required',
  validationLatitudeMustBeNumber: 'Latitude must be a number',
  validationLatitudeRange: 'Latitude must be a number between -90 and 90',
  validationLatitudeRequired: 'Latitude is required',
  validationLastNameInvalidCharacters: 'Last Name must not contain special characters',
  validationLastNameInvalidType: 'Last Name must be a string',
  validationLastNameMaxLength: 'Last Name must contain at most 50 characters',
  validationLastNameMinLength: 'Last Name must contain at least 2 characters',
  validationLastNameRequired: 'Last Name is required',
  validationLongitudeMustBeNumber: 'Longitude must be a number',
  validationLongitudeRange: 'Longitude must be a number between -180 and 180',
  validationLongitudeRequired: 'Longitude is required',
  validationManagerNameInvalidCharacters: 'Manager Name must not contain special characters',
  validationManagerNameInvalidType: 'Manager Name must be a string',
  validationManagerNameMaxLength: 'Manager Name must contain at most 50 characters',
  validationManagerNameMinLength: 'Manager Name must contain at least 4 characters',
  validationManagerNameRequired: 'Manager Name is required',
  validationNameInvalidCharacters: 'Name must not contain special characters',
  validationNameInvalidType: 'Name must be a string',
  validationNameMaxLength: 'Name must contain at most 50 characters',
  validationNameMinLength: 'Name must contain at least 2 characters',
  validationNameRequired: 'Name is required',
  validationOrganizationIdInvalidType: 'Please select organization',
  validationOrganizationIdRequired: 'Organization is required',
  validationOrganizationNameInvalidCharacters:
    'Organization Name must not contain special characters',
  validationOrganizationNameInvalidType: 'Organization Name must be a string',
  validationOrganizationNameMaxLength: 'Organization Name must contain at most 50 characters',
  validationOrganizationNameMinLength: 'Organization Name must contain at least 2 characters',
  validationOrganizationNameRequired: 'Organization Name is required',
  validationPhoneInvalidCharacters: 'Phone must be valid',
  validationPhoneInvalidType: 'Phone must be a string',
  validationPhoneMaxLength: 'Phone must contain at most 20 characters',
  validationPhoneMinLength: 'Phone must contain at least 4 characters',
  validationPhoneRequired: 'Phone is required',
  validationPostalCodeInvalidCharacters: 'Postal Code must be valid',
  validationPostalCodeInvalidType: 'Postal Code must be a string',
  validationPostalCodeMaxLength: 'Postal Code must contain at most 10 characters',
  validationPostalCodeMinLength: 'Postal Code must contain at least 4 characters',
  validationPostalCodeRequired: 'Postal Code is required',
  validationRoleNameInvalidType: 'Role Name must be a valid UserRole',
  validationRoleNameRequired: 'Role Name is required',
  validationSiteInvalidNumber: 'Site must be a number',
  validationSiteRequired: 'Site is required',
  validationStreetInvalidCharacters: 'Street must not contain special characters',
  validationStreetInvalidType: 'Street must be a string',
  validationStreetMaxLength: 'Street must contain at most 50 characters',
  validationStreetMinLength: 'Street must contain at least 2 characters',
  validationStreetRequired: 'Street is required',
  validationMetricsMin: 'Please select at least 1 metric',

  //
  // 21) Aria Labels
  //
  ariaLabelBookmarkSite: 'Bookmark Site',
  ariaLabelUnbookmarkSite: 'Unbookmark Site',

  //
  // 22) Action Names
  //
  actionNameCreateNotificationRule: 'Create notification rule',
  actionNameDeleteNotificationRule: 'Delete notification rule',
  actionNameUpdateNotificationRule: 'Update notification rule',
};

export type DictionaryKeys = keyof typeof dictionary;
